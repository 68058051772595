body {
    font-size: 14px;
    position: relative;
    padding-top:74px;
}
a:hover {
    text-decoration: none;
}
.loading-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    z-index: 200;
    top: 0;
    left: 0;
}
.loading-screen-img {
    margin: 0 auto;
    width: 100%;
}
.loading-screen img {
    width: 100px;
    height: 100px;
}
.load-more img {
    width: 50px;
    height: 50px;
}
.over-hidden {
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: fixed;
}
.form-control:focus {
    box-shadow: none;
}
.header {
    background: url(/221a10a8551f6048a4fa5ca2686e8491.png);
    background-size: 100% 100%;
    height: 74px;
    position: fixed;
    top: 0;
    left: 15px;
    z-index: 100;
    width: 100%;
}
.menu-divide {
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.menu-left {
    float: left;
    padding-top: 25px;
    margin-right: 20px;
}
.menu-img {
    width: 25px;
}
.menu-content {
    width: 70%;
    background: url(/23467075ba1065b77f63f1718c50e7ba.png);
    background-size: 100%;
    position: fixed;
    z-index: 5;
    left: -70%;
    top: 74px;
    transition: all 0.2s ease 0.2s;
}
.menu-overlay {
    width: 100%;
    left: 0;
    top: 74px;
    position: fixed;
    background: rgba(4, 24, 39, 0.5);
    display: none;
    z-index: 1;
}
.menu-overlay-2 {
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 200;
}
.menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    min-height: 500px;
}
.menu-list li a {
    padding: 12px 20px 12px 50px;
    float: left;
    width: 100%;
    position: relative;
    color: #fff !important;
}
.menu-list li a img {
    position: absolute;
    left: 22px;
    width: 18px;
    top: 12px;
}
.menu-list-login {
    background: url(/d0182272db357b753ebe8ae3b07bfdc1.png);
    background-size: 100% 100%;
    color: #fff;
    /*text-transform: uppercase;*/
    font-weight: 500;
}
.menu-list-login-img {
    width: 18px;
}
.list-group {
    float: left;
    width: 100%;
}
.sub-menu {
    color: #fff !important;
}
.sub-menu-content {
    display: none;
}
.sub-menu-content .list-group-item {
    background: none;
    border: none;
}
.sub-menu-content-2 {
    display: none;
}
.sub-menu-content-2 .list-group-item {
    background: none;
    border: none;
}
.logo-center {
    float: left;
    padding-top: 7px;
}
.logo-center-2 {
    padding-top: 27px;
    position: absolute;
    padding-left: 40px;
    z-index: -1;
}
.logo-center-2 a {
    color: #d1c835;
    text-transform: uppercase;
    font-weight: 500;
}
.logo-img {
    width: 110px;
}
.search-right {
    float: right;
    padding-top: 18px;
}
.search-input {
    width: 127px;
    transition: all 0.2s ease 0.2s;
}
.search-input .input-group-text {
    border: none;
    border-radius: 0px 100px 100px 0px;
    background: #fff;
}
.search-input .form-control {
    border: none;
    border-radius: 100px 0px 0px 100px;
    font-size: 14px;
    padding-right: 0;
}
.search-img {
    width: 20px;
}
.slide-img {
    width: 100%;
}
.list-content {
    margin-top: 10px;
}
.list-content-btn {
    background: url(/0d721e80f9dcaf534dd0ef857120a2b3.png) no-repeat;
    background-size: 100% 100%;
    font-size: 14px;
    color: #feda7b;
    text-transform: uppercase;
    padding: 12px 20px;
    font-weight: 500;
    border-radius: 100px;
}
.content-item img {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 2px;
}
.content-item-head {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    max-height: 42px;
    width: 100%;
    overflow: hidden;
    padding-left: 10px;
}
.content-item-sub {
    font-size: 12px;
    color: #a8acbb;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
}
.content-view-more {
    color: #000;
    margin-top: 10px;
    font-weight: 300;
    font-size: 14px;
}
.text-white {
    color: #fff !important;
}
.content-view-more-img {
    width: 12px;
    margin-left: 5px;
}
.content-item-singer {
    border-radius: 100px !important;
    border: 4px solid #3f09a0;
}
.category-group {
    background: #ebebeb;
    padding: 7px 0px;
    position: fixed;
    top: 74px;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: all 0.2s ease 0.2s;
}
.category-slide {
    padding: 0px 10px;
}
.category-slide-item {
    padding: 6px 10px;
    border-right: 0.1px solid #6c6c6c;
}
.category-slide-item a {
    color: #000;
}
.category-slide .slick-prev:before {
    content: '';
}
.category-slide .slick-prev {
    background: url(/b1a5da9737d1f6b661850a603d816520.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    left: -14px;
    transform: scale(0.5);
    top: -5px;
}
.category-slide .slick-next:before {
    content: '';
}
.category-slide .slick-next {
    background: url(/3682abae86848dd612d7b3fd2c653290.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    right: -14px;
    transform: scale(0.5);
    top: -5px;
}
.category-active {
    /*background: #fff;*/
    font-weight: 600;
}
/**/
.home-content-slide .slick-prev:before {
    content: '';
}
.home-content-slide .slick-prev {
    background: url(/b1a5da9737d1f6b661850a603d816520.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    left: -14px;
    transform: scale(0.7);
    top: 45px;
}
.home-content-slide .slick-next:before {
    content: '';
}
.home-content-slide .slick-next {
    background: url(/3682abae86848dd612d7b3fd2c653290.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    right: -14px;
    transform: scale(0.7);
    top: 45px;
}
.home-content-slide .slick-disabled {
    display: none !important;
}
/**/
.video-info {
    margin-top: 210px;
}
.video-info-head {
    font-size: 22px;
    font-weight: 700;
    color: #0b395d;
    margin-top: 10px;
}
.video-info-sub-2 {
    font-size: 12px;
    color: #858585;
    margin-top: 2px;
}
.video-relate-head {
    font-size: 16px;
    font-weight: 700;
    color: #0b395d;
    margin-top: 15px;
}
.video-menu-parent {
    position: relative;
}
.video-menu-group {
    width: 100%;
}
.video-menu {
    width: 5px;
    margin-top: 18px;
}
.video-menu-content {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 170px;
    z-index: 300;
    display: none;
}
.video-menu-content .list-group-item {
    position: relative;
    padding: 10px 15px;
    padding-left: 50px;
}
.video-menu-content .list-group-item img {
    left: 17px;
    top: 11px;
    position: absolute;
}
.video-relate-autoplay {
    font-size: 14px;
    font-weight: 500;
    color: #0b395d;
    margin-top: 16px;
    float: right;
    margin-right: 10px;
}
.video-relate-img {
    width: 100%;
    margin-bottom: 20px;
}
.video-relate-content {
    margin-top: 20px;
}
.video-relate-title {
    font-size: 14px;
    font-weight: 500;
    color: #0b395d;
    max-height: 44px;
    overflow: hidden;
}
.video-relate-sub {
    color: #090909;
    font-size: 12px;
}
.login-group {
    background: url(/c251475d7dd48c21459bb41a0f7be90b.png);
    background-size: 100% 100%;
}
.login-form {
    margin-top: 100px;
    padding: 0 20px;
}
.login-form-head {
    text-transform: uppercase;
    font-weight: 600;
    color: #e7cd0d;
    font-size: 20px;
}
.login-form-text {
    color: #e7cd0d;
    font-size: 14px;
}
.login-form .form-control {
    border-radius: 100px;
    margin-bottom: 20px;
    font-size: 14px;
}
.login-btn {
    background: #dbb049;
    color: #fff;
    border-radius: 100px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 7px 20px;
}
.footer {
    background: url(/7182780ad3839f51037954c34649a684.png);
    background-size: 100% 100%;
    color: #fff;
    margin-top: 30px;
    font-size: 12px;
}
.footer-text {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.slick-slide {
    height: auto;
}

/* Swift */
.switch-video {
    margin-top: 22px;
    float: right;
}
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 12px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -4px;
    background-color: #838383;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #c2c2c2;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #c2c2c2;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background: #0b395d;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

.player-minimize .player-position {
    background-color: white;
    border-radius: 2px;
    bottom: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    right: 10px;
    padding: 7px;
    position: fixed;
    width: 50%;
    z-index: 1;
}

.modal-alert-header {
    background: #dbb049;
    color: #fff;
    font-size: 14px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px;
    text-align: center;
}

.modal-alert-btn {
    width: 100%;
    background: #dbb049;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    border: none;
}

.modal-download-btn {
    background: #dbb049;
    color: #fff;
    font-size: 14px;
    border: none;
}

.modal-dialog {
    top: 100px;
}

.favorite-text {
    font-size: 16px;
    font-weight: 600;
    color: #0b395d;
    padding-top: 10px;
}
.favorite-menu {
    width: 5px;
    margin-top: 2px;
    margin-left: 12px;
}
.favorite-menu-content {
    width: 100%;
    position: fixed;
    bottom: -300px;
    left: 0;
    z-index: 300;
    background: #fff;
    transition: all 0.2s ease 0.2s;
}
.favorite-menu-content-1 {
    padding: 20px 20px 0px 20px;
}
.favorite-menu-content-2 {
    border-top: 1px solid #707070;
}
.video-menu-close {
    text-align: center;
    padding: 15px;
    width: 100%;
}
.video-menu-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
}
.video-menu-item img {
    position: absolute;
    top: 2px;
    left: 0;
}

.banner-slider .slick-dots {
    bottom: 10px;
}
.banner-slider .slick-dots li {
    margin: 0px 0px;
}
.banner-slider .slick-dots li button:before {
    color: #fff;
    font-size: 10px;
    opacity: 0.7;
}
.banner-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}
.artist-slider .slick-next {
    top: 72px;
}
.artist-slider .slick-prev {
    top: 72px;
}
.width-max {
    width: 100%;
}
.btn-ranger {
    flex: 10 10 auto !important;
}
.dropdown-control {
    font-size: 14px;
    min-width: auto;
    padding: 0;
}
.video-quality-active {
    background: #0b395d;
    color: #fff;
}
.package-item {
    border: 4px solid #1a099a;
}
.package-item-header {
    text-transform: uppercase;
    color: #0b395d;
    padding: 10px;
    background: #e1e1e1;
    font-weight: 600;
}
.package-item-content {
    padding: 10px;
    background: #fff;
}
.package-title {
    text-transform: uppercase;
    color: #0b395d;
    font-weight: 600;
}
.package-sub {
    color: #0b395d;
}
.package-user {
    background: #dbb049;
    padding: 15px 0px;
}
.package-user-title {
    color: #fff;
    font-weight: 600;
}

/*Ranger*/
[type='range'] {
    -webkit-appearance: none;
    margin: 6px 0;
    width: 100%;
}

[type='range']:focus {
    outline: 0;
}

[type='range']:focus::-webkit-slider-runnable-track {
    background: #c2c2c2;
}

[type='range']:focus::-ms-fill-lower {
    background: #eee;
}

[type='range']:focus::-ms-fill-upper {
    background: #c2c2c2;
}

[type='range']::-webkit-slider-runnable-track {
    cursor: pointer;
    height: 10px;
    transition: all .2s ease;
    width: 100%;
    box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
    background: #c2c2c2;
    border: 0 solid #000;
    border-radius: 5px;
}

[type='range']::-webkit-slider-thumb {
    box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
    background: #0b395d;
    border: 0 solid #fff;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    -webkit-appearance: none;
    margin-top: -1px;
}

[type='range']::-moz-range-track {
    cursor: pointer;
    height: 10px;
    transition: all .2s ease;
    width: 100%;
    box-shadow: 0 0 0 #222, 0 0 0 #c2c2c2;
    background: #c2c2c2;
    border: 0 solid #000;
    border-radius: 5px;
}

[type='range']::-moz-range-thumb {
    box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
    background: #c2c2c2;
    border: 0 solid #fff;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px;
}

[type='range']::-ms-track {
    cursor: pointer;
    height: 10px;
    transition: all .2s ease;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
}

[type='range']::-ms-fill-lower {
    box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
    background: #c2c2c2;
    border: 0 solid #000;
    border-radius: 10px;
}

[type='range']::-ms-fill-upper {
    box-shadow: 0 0 0 #222, 0 0 0 #2f2f2f;
    background: #c2c2c2;
    border: 0 solid #000;
    border-radius: 10px;
}

[type='range']::-ms-thumb {
    box-shadow: 0 0 0 #111, 0 0 0 #1e1e1e;
    background: #c2c2c2;
    border: 0 solid #fff;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin-top: 0;
}

/*Player*/
.player-portrait {
    position: fixed;
    top: 74px;
    left: 0;
    width: 100%;
    z-index: 1;
}
.player-landscape {
    display: block;
    width: 100%;
}
.jw-nextup-header {
    font-weight: normal !important;
    white-space: nowrap;
}
.jw-related-btn {
    display: none !important;
}
.jw-settings-sharing {
    display: none !important;
}
.jw-nextup-container {
    bottom: 68px !important;
}

.jw-state-idle .jw-svg-icon-play {
    display: none !important;
}
.jw-state-idle .jw-svg-icon-replay {
    display: block !important;
}
.jw-title-primary {
    display: none;
}

/*BXH*/
.bxh-group {
    background: url(/1d36061d7fa35fd2c01634f3de376ff7.png);
    background-size: 100% 100%;
    padding: 20px 0px;
}
.bxh-head {
    color: #e7cd0d;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}
.bxh-item {
    background: rgba(255, 255, 255, 0.2);
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: block;
}
.bxh-item-img {
    width: 100%;
}
.bxh-item-score {
    height: 20px;
    float: left;
}
.bxh-item-score-text {
    line-height: 20px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
}
.bxh-divide {
    height: 1px;
    background: #fff;
    width: 35px;
    margin-top: 5px;
}
.bxh-item-name {
    color: #fff;
    font-weight: 500;
    max-height: 40px;
    overflow: hidden;
    margin-top: 5px;
}
.bxh-item-singer {
    color: #bdb9ce;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
/*Chuyen muc moi*/
.home-group {
    background: #ebebeb;
    padding: 20px 0;
    font-size: 12px;
}
.home-head {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}
.home-item {
    padding: 25px;
}
.home-item img {
    width: 100%;
}
.home-item-text {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.home-slider .slick-active .home-item {
    transition: all 300ms ease;
}
.home-slider .slick-center .home-item {
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
}
.home-slider .slick-prev:before {
    content: '';
}
.home-slider .slick-prev {
    background: url(/b1a5da9737d1f6b661850a603d816520.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    left: 0px;
    transform: scale(0.8);
    top: 43px;
}
.home-slider .slick-next:before {
    content: '';
}
.home-slider .slick-next {
    background: url(/3682abae86848dd612d7b3fd2c653290.png) no-repeat !important;
    background-size: 100%;
    height: 42px;
    width: 21px;
    z-index: 2;
    right: 0px;
    transform: scale(0.8);
    top: 43px;
}
/*Rank*/
.bg-rank {
    min-height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
.rank-group {
    /*background: url(../images/bg-bxh.png);*/
    /*background-size: 100% 100%;*/
}
.rank-head {
    background: url(/1d36061d7fa35fd2c01634f3de376ff7.png);
    background-size: 100%;
    background-position-y: -45px;
    padding: 10px;
    height: 80px;
    position: fixed;
    top: 74px;
    left: 0;
    z-index: 1;
}
.rank-head-text {
    color: #d1c835;
    text-transform: uppercase;
    font-weight: 600;
}
.rank-head .progress {
    margin-top: 2px;
    height: 6px;
}
.rank-head .progress .progress-bar {
    background-image: linear-gradient(to left, #dcb222, #9d7322);
}
.rank-list {
    margin-top: 85px;
}
.rank-text-pos {
    color: #d1c835;
    font-weight: 600;
}

/*Search Suggest*/
.search-suggest {
    display: block;
    left: auto;
    top: 60px;
    right: 15px;
    padding: 0px;
}
.search-suggest .dropdown-header {
    background: #2b71bb;
    color: #fff;
    padding: 10px 1.5rem;
}
.search-suggest .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 1.5rem;
    border-bottom: 1px solid #ddd;
}
.search-history {
    font-size: 12px;
    color: #6e7687;
    background-color: #e9ecef;
    border: none;
    padding: 0 .5rem;
    line-height: 2em;
    border-radius: 100px;
}
/* NAV */
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0b395d;
    border-radius: 100px;
}
.nav-pills .nav-link {
    color: #000;
}
.nav-link {
    padding: 6px 1rem;
}
/*Share*/
.share-group {
    display: flex;
}
.share-item {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}
.share-item-link {
    width: 50px;
    height: 50px;
    background: #838383;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jw-nextup-tooltip {
    display: none !important;
}
.content-item-time-right {
    position: absolute;
    right: 8px;
    bottom: 10px;
    width: 100%;
    text-align: right;
}
.content-item-time-left {
    position: absolute;
    right: 8px;
    bottom: 10px;
    width: 100%;
    text-align: right;
}
.content-item-time {
    display: inline;
    font-size: 10px;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    padding: 2px 4px;
}
